import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component<GatsbyProps> {
    render() {
        const pageName = '404: Not Found'

        return (
            <Layout location={this.props.location}>
                <main className="container mx-auto p-4">
                    <h1>{pageName}</h1>
                    <p>Aceasta pagina nu exista.</p>
                </main>
            </Layout>
        )
    }
}

export default NotFoundPage
